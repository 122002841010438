import { Button } from "@mui/joy";
import { Link } from "react-router-dom";
import styles from "./LinkButton.module.css";

const LinkButton = ({children , link , target}) => {
  return <Link className={styles.LinkButton} to={link} rel="noreferrer" target={target} >
    <Button variant="soft">{children}
    </Button></Link>;
};

export default LinkButton;
