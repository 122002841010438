import NavigationItem from "./NavigationItem/NavigationItem";
import styles from './Navigation.module.css';
const Navigation = ({Horizontal}) => {
  return (
    <nav className={[styles.Navigation , Horizontal ? styles.Horizontal : null].join(' ')}>
      <NavigationItem link="/home">خانه</NavigationItem>
      <NavigationItem link="/events">رویدادها</NavigationItem>
      <NavigationItem link="/about">تیم برگزار کننده</NavigationItem>
      <NavigationItem blank link="/blog">بلاگ</NavigationItem>
      <NavigationItem link="/contact">تماس با ما</NavigationItem>
    </nav>
  );
};

export default Navigation;
