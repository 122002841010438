import { CssVarsProvider, extendTheme } from "@mui/joy";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Switch } from "react-router-dom";
import LoadingPage from "./Components/LoadingPage/LoadingPage";
//Containers
import About from "./Containers/About/About";
import Contact from "./Containers/Contact/Contact";
import Events from "./Containers/Events/Events";
import Home from "./Containers/Home/Home";
import Layout from "./Containers/Layout/Layout";
import Notfound from "./Containers/Notfound/Notfound";
import Personalities from "./Containers/Personalities/Personalities";
const Signup = React.lazy(() => import('./Containers/Signup/Signup'));
const Ticket = React.lazy(() => import('./Containers/Ticket/Ticket'));
const theme = extendTheme({
  colorSchemes: {
    dark: {
      palette: {
        primary: {
          solidBg: 'rgba(0, 158, 226, 1)',
          // solidColor: '#1d1d1b',
          solidHoverBg: 'rgba(0, 140, 200, 1)',
          solidActiveBg: 'rgba(0, 120, 180, 1)',
          softColor: 'rgba(0, 158, 226, 1)',
          softBg: 'rgba(0, 158, 226, 0.1)',
          softHoverBg: 'rgba(0, 158, 226, 0.25)',
          softActiveBg: 'rgba(0, 158, 226, 0.5)',
          plainColor: 'rgba(0, 158, 226, 1)',
          "500": 'rgba(0, 158, 226, 1)'
        },
        neutral: {

        },
        focusVisible: 'rgba(0, 158, 226, 0.3))',

      },

    },
  },

})
function App() {
  return (
    <CssVarsProvider theme={theme} defaultMode="dark"
      // the selector to apply CSS theme variables stylesheet.
      colorSchemeSelector="#demo_dark-mode-by-default"
      // the local storage key to use
      modeStorageKey="demo_dark-mode-by-default">
      <div id="demo_dark-mode-by-default">
        <Switch>

          <Route path="/ticket/:id">
            <Suspense fallback={<LoadingPage />}>
              <Ticket />
            </Suspense>
          </Route>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Layout>
            <Switch>
              <Route path="/signup">
                <Suspense fallback={<LoadingPage />}>
                  <Signup />
                </Suspense>
              </Route>
              <Route path="/home">
                <Home />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/events">
                <Events />
              </Route>
              <Route path="/personalities">
                <Personalities />
              </Route>
              <Route>
                <Notfound />
              </Route>
            </Switch>
          </Layout>

        </Switch>

      </div>
    </CssVarsProvider>
  );
}

export default App;
