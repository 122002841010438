import { Helmet } from "react-helmet"

const Head = ({ title , description , image}) => {
    return <Helmet>

        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://metatags.io/" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />


        <meta property="twitter:card" content={image} />
        <meta property="twitter:url" content="https://flatearthevent.ir" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />
    </ Helmet>
}
export default Head;