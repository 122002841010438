
import { useEffect, useState } from "react";
import api from "../../api/api";
import Event from "../../Components/Event/Event";
import Head from "../../Components/Head/Head";
import styles from './Events.module.css';

const Events = () => {
    // console.log(EventsData)
    const [activeEvent , setActiveEvent] = useState(null);
    const [data , setData] = useState(null);
    const activeEventHandler = (index)=>{
        setActiveEvent(index);
        window.scrollTo({top : 0 , behavior : 'smooth'})
    }
    useEffect(()=>{
        api.get('/events').then((res)=>{
            setData(res.data)
    
        })
    } , [])
    return (
        <div>
            <Head title="رویداد زمین صاف - رویدادها " description="تغییر دهنده زمین صاف باش!" />
            <section className={[styles.Section, styles.About].join(' ')}>
                <div className={styles.Content}>
                    
                    <div className={styles.EventsContainer}>
                        {Array.isArray(data) ? data.map((el , index) => {
                            return <Event 
                            key={el.id}
                            clickHandler={()=>{activeEventHandler(index)}}
                            isActive={activeEvent === index}
                            allEvents = {()=>{activeEventHandler(100000)}}
                            {...el}
                          />
                        }) : new Array(4).fill(' ').map(()=> <Event loading />) }
                    </div>

                </div>
            </section>
        </div>
    )
}
export default Events;