import styles from './BlurCircle.module.css';
const BlurCircle = ({size , top , left , right , bottom , color , opacity})=>{
    return (
        <div 
        style={{
            width : size,
            height : size,
            top,left,right,bottom,opacity,
            backgroundColor : color,

        }}
        className={styles.BlurCircle}>

        </div>
    )
}
export default BlurCircle;