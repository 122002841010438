import Logo from "../Logo/Logo";
import styles from './Footer.module.css';

const Footer = (props) => {
    return (
        <footer className={styles.Footer}>
            <p>&copy; All rights reserved</p>
            <Logo />
        </footer>
    )
}

export default Footer;