
import { useEffect, useState } from "react";
import LinkButton from "../Buttons/LinkButton/LinkButton";
import HamMenu from "../HamMenu/HamMenu";
import Logo from "../Logo/Logo";
import Navigation from "../Navigation/Navigation";
import styles from './Header.module.css';

const Header = (props) => {
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        window.innerWidth > 900 ? setIsMobile(false) : setIsMobile(true);
        window.addEventListener('resize', (e) => {
            window.innerWidth > 900 ? setIsMobile(false) : setIsMobile(true);
        })
    } , [isMobile])
    return (
        <header className={styles.Header}>
            <HamMenu />
            {isMobile ? null :<>
                <LinkButton link="/signup">ثبت نام در رویداد</LinkButton>
                <Navigation />
            </>}
            <Logo />
        </header>
    )
}

export default Header;