import { Button } from '@mui/joy';
import Speaker from '../Speaker/Speaker';
import Sponsor from '../Sponsor/Sponsor';
import styles from './Event.module.css';
const Event = ({loading , name, detail, image, description, clickHandler, isActive, speakers, sponsers, allEvents }) => {
//    console.log(speakers)
    return <a href={'#' + name}  className={[styles.EventContainer ].join(' ')} style={{
        // backgroundImage : `url(${image})`,
        order: isActive ? 0 : 1,
        width: isActive ? '100%' : 'auto',
        position : 'relative'
    }}><div onClick={isActive ? null : clickHandler } style={{
        backgroundImage: `
        ${image ? 'linear-gradient(to top , rgba(0, 0, 0, 0.75) 30% , transparent 50% ),' : ''}
        url(${image})`,
        // order: isActive ? 0 : 1
    }} className={[styles.Event, isActive ? styles.Active : null , loading ? 'skeleton-box' : null].join(' ')}>

            <h2 className={styles.Name}>{name}</h2>
            <p className={styles.Detail}>{detail}</p>
            {isActive ? <Button  className={styles.AllEventsButton}  onClick={allEvents}>
                همه رویدادها
            </Button> : null}

        </div>
        {isActive ? <div className={styles.EventContent}>
            <h2>توضیجات</h2>
            <p className={styles.Description}>{description}</p>
            
            <div>
                <h2>سخنرانان</h2>
                <div className={styles.Speakers}>
                    {Array.isArray(speakers) && [...speakers].length > 0  ? speakers.map((el, index) => {
                        return <Speaker {...el} />
                    }) : <div>هنوز سخنرانی مشخص نشده است.</div>}
                </div>
            </div>
            <div>
                <h2>حامیان رویداد</h2>
                <div className={styles.Sponsors}>
                    {sponsers ? sponsers.map((el)=>{
                        console.log(el)
                        return <Sponsor  {...el} link={el.video_link} />
                    }) : null}
                </div>
            </div>
            
        </div> : null}
      
    </a>
}
export default Event;