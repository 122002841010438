import styles from './Speaker.module.css';

const Speaker = ({ image, name, detail, linkedin, video_link }) => {
    return (
        <div className={styles.SpeakerContainer}>
            <div className={styles.Speaker}>
                <div className={styles.NameAndImage}>
                <img className={styles.Image} src={image} alt={name} />
                <h2 className={styles.Name}><span>{name}</span>{linkedin ? <a target="_blank" rel="noreferrer"
                    className={styles.SocialMediaIcon} href={'https://www.linkedin.com/' + linkedin}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                        <g  ><path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z"></path><path d="m.396 7.977h4.976v16.023h-4.976z"></path><path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"></path></g>
                    </svg>
                </a> : null}</h2>
                </div>
                <p className={styles.Detail}>{detail}</p>
            </div>
            <iframe 
            className={styles.Video} 
            src={video_link} 
            allowFullScreen="true" 
            webkitallowfullscreen="true" 
            mozallowfullscreen="true">
            </iframe>

        </div>
    )
}

export default Speaker;