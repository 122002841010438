import GoUpButton from "../../Components/Buttons/GoUpButton/GoUpButton";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import styles from './Layout.module.css';

const Layout = (props) => {
  return (
    <div className={styles.Layout}>
      <Header />
      <main className={styles.Content}>
      {props.children}
      </main>
      <GoUpButton />
      <Footer />
    </div>
  );
};

export default Layout;
