import { Button } from '@mui/joy';
import { Link } from 'react-router-dom';
import styles from './Notfound.module.css'
const Notfound = ()=>{
    return <div className={styles.Notfound}>
        <img src="/img/assets/404.svg" alt="پیدا نشد" />
       
        <h3 className={styles.Title}>صفحه رو نتونستیم پیدا کنیم!</h3>
        <Link link="/home">
            
            <Button>بازگشت به خانه
            </Button>
            </Link>
    </div>
}
export default Notfound;