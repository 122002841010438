import { NavLink } from "react-router-dom";
import styles from './NavigationItem.module.css';

const NavigationItem = ({children , link , blank})=>{
    return (
        <NavLink
        target={blank ? '_blank' : '_self'}
        activeClassName={styles.ActiveNavigationItem} 
        className={styles.NavigationItem} to={link}>{children}</NavLink>
    )
}

export default NavigationItem;