var persianDigits = "۰۱۲۳۴۵۶۷۸۹";
var persianMap = persianDigits.split("");

export function convertToEnglishNumber(input){
    return input.replace(/[\u06F0-\u06F90]/g, function(m){
        return persianDigits.indexOf(m);
    });
}
export function convertToPersianNumber(input){
    return input.replace(/\d/g,function(m){
        return persianMap[parseInt(m)];
    });
}

// tests
